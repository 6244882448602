const method_of_loading = {
    name: 'method_of_loading',
    title: 'Способы погрузки',
    url: '/refs/method_of_loading',
    include: [],
    filterBy: ['title'],
    perms: {
        list: 'v1.method_of_loading.list',
        read: 'v1.method_of_loading.read',
        create: 'v1.method_of_loading.create',
        update: 'v1.method_of_loading.update',
        delete: 'v1.method_of_loading.delete',
    },
    listColumns: [
        {
            name: 'title',
            required: true,
            label: 'Наименование',
            align: 'left',
            field: 'title',
            sortable: true,
        },
    ],
    editColumns: [
        {
            name: 'title',
            required: true,
            label: 'Наименование',
            align: 'left',
            field: 'title',
            sortable: true,
        },
    ],
};

export {
    method_of_loading,
};
